/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { css } from '@emotion/react';
import { Helmet } from 'react-helmet';

import GlobalStyles from './GlobalStyles';

type Props = {};

const DefaultLayout: React.FC<Props> = ({
  children,

  ...props
}) => {
  return (
    <>
      <GlobalStyles />
      <Helmet>
        <link rel="shortcut icon" type="image/svg+xml" href="/favicon.svg" />
        <script
          src="https://fast.wistia.com/embed/medias/vw4oxt0ehz.jsonp"
          async
        />
        <script src="https://fast.wistia.com/assets/external/E-v1.js" async />
      </Helmet>
      <div
        css={css`
          /* stylelint-disable */
          overflow: hidden;
        `}
        {...props}
      >
        {children}
      </div>
    </>
  );
};

export default DefaultLayout;
