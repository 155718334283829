import React from 'react';
import { Global, css } from '@emotion/react';
import { normalize } from 'polished';

const GlobalStyles: React.FC = () => {
  return (
    <Global
      styles={css`
        /* stylelint-disable */
        ${normalize()} :root {
          --turquoise-blue-color: #65ebae;
          --mercury-color: #e8e8e8;
          --primary-color: var(--turquoise-blue-color);
          --primary-font-family: 'Montserrat', sans-serif;
          --primary-font-weight: 400;
          --primary-font-color: black;
          --primary-font-size: 0.825rem;
        }

        html,
        body {
          font-family: var(--primary-font-family);
          font-weight: var(--primary-font-weight);
          color: var(--primary-font-color);
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-family: var(--primary-font-family);
          font-weight: var(--primary-font-weight);
          color: var(--primary-font-color);
          margin-top: 0;
          margin-bottom: 24px;
        }

        a {
          color: var(--primary-font-color);
          font-weight: bold;
          text-decoration: none;
        }

        a:hover,
        a:focus {
          text-decoration: underline;
        }

        img {
          max-width: 100%;
        }

        h1 {
          font-size: 1.5rem;
        }

        h2,
        h3,
        h4 {
          font-size: var(--primary-font-size);
          font-weight: bold;
        }
      `}
    />
  );
};

export default GlobalStyles;
